.dropdownWrapper {
  display: inline-block;
  width: fit-content;
}

.filterItemsWrapper {
  padding: 0;
}

.filterItem {
  max-width: 250px;
  white-space: wrap;
}
