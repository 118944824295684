.titleContainer {
  padding: 24px 0;
}

.title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.tableauTableWidget {
  width: 100%;
  border-collapse: collapse;
}

.tableauTableWidgetContainer {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 32px;
}

.tableauTableWidgetBody {
  padding: 12px 16px 16px;
  overflow-x: scroll;
}

.tableauTableWidgetHead {
  padding: 16px 16px 0;
}

.tableauTableWidget thead tr {
  background: #fff;
  vertical-align: middle;
}

.tableauTableWidget thead tr th {
  padding: 11px 0 11px 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1f2937;
  vertical-align: middle;
}

.tableauTableWidget tbody tr {
  padding: 0;
  vertical-align: middle;
}

.tableauTableWidget tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.tableauTableWidget td {
  border: none;
}

.tableauTableWidget tbody tr td {
  padding: 11px 0 11px 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4b5563;
  vertical-align: middle;
}

.expandButton {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: 12px;
}

.expandButton span {
  font-size: 16px;
}

.tableauTableWidget td span {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.tableauTableWidget td button span {
  height: 1rem;
}

.subHeader {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #1f2937;
}

.leftCell {
  text-align: left;
  width: 100%;
  display: inline-block;
}

.centeredCell {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.rightCell {
  text-align: end;
  width: 100%;
  display: inline-block;
}

.activityTableHeader {
  min-width: 120px;
  font-size: 12px;
  line-height: 18px;
  color: #1f2937;
  padding: 4px 1px;
  vertical-align: middle;
  text-align: center;
}

.sortIcon {
  font-size: 16px;
}

.tableauTablePagerContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
  border-top: 1px solid #e6e6e6;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.hidden {
  color: grey;
}

.searchInput {
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #d1d5db;
}
