.SectionsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.ConfigurationSectionBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.ConfigurationSectionHeading {
  margin-bottom: 16px;
}
